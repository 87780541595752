<template>
    <div class="produtos">
        <div class="container">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Produtos</h1>
                    </div>
                </div>
            </div>
            <div class="col-12  mt-5">
                <div class="row">
                    <router-view :key="$route.fullPath" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.produtos {
    @include background;
    .titulo {
        @include titulo;
    }
    .btn-site {
        @include btn-site;
    }
}
@media (max-width: 767.98px) {
    .produtos {
        padding: 50px 0 80px;
    }
}
</style>